<template>
  <div>
    <div v-if="editable && isOld && !disabled" class="col float-right">
      <q-btn-group outline>
        <c-btn v-if="editable && isOld && !disabled" label="LBLREMOVE" icon="remove" @btnClicked="deleteData" />
        <c-btn
          v-show="editable && isOld && !disabled"
          :url="saveUrl"
          :isSubmit="isSave"
          :param="data"
          :mappingType="mappingType"
          label="완료"
          icon="check"
          @beforeAction="saveDataEnd"
          @btnCallback="saveCallbackEnd" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param.sync="param"
          @saveCallback="saveCallback"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'compliance-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',  // 평가항목 개정번호
        count: 0,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      saveUrl: '',
      mappingType: 'PUT',
      isSave: false,
      editable: true,
      splitter: 5,
      tab: 'planInfo',
      data: {
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: 0,
        chgUserId: '',
      },
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '감사계획정보', component: () => import(`${'./planInfo.vue'}`) },
        { key: uid(), name: 'planTeam', icon: 'checklist', label: '감사팀', component: () => import(`${'./planTeam.vue'}`), disabled: (this.param.selfInspectionResultId ? false : true)},
        { key: uid(), name: 'planItem', icon: 'category', label: '감사항목', component: () => import(`${'./planItem.vue'}`), disabled: (this.param.selfInspectionResultId ? false : true) },
        { key: uid(), name: 'auditTable', icon: 'table_view', label: '환산표', component: () => import(`${'./auditTables.vue'}`), disabled: (this.param.selfInspectionResultId ? false : true) },
      ]
    },
    isOld() {
      return Boolean(this.param.selfInspectionResultId)
    },
    disabled() {
      return this.param.selfInspectionStatusCd !== 'SISC000001'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.saveUrl = transactionConfig.sop.pca.result.info.planend.url
      this.deleteUrl = transactionConfig.sop.pca.result.info.delete.url;
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '자체감사의 모든정보가 삭제됩니다. 정말로 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.selfInspectionResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveDataEnd() {
      let saveMsg = '계획을 완료하시겠습니까?';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: saveMsg,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.selfInspectionResultId = this.param.selfInspectionResultId;
          this.data.selfInspectionStatusCd = 'SISC000002';
          this.data.sysRevision = this.param.sysRevision
          this.data.regUserId = this.$store.getters.user.userId;
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallbackEnd() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // stepper 이동
      this.$emit('emitStep', {
        name: 'currentStep',
        param: 'SISC000002'
      })
    },
    saveCallback(data) {
      this.$set(this.param, 'selfInspectionResultId', data.selfInspectionResultId)
      this.$set(this.param, 'selfInspectionStatusCd', data.selfInspectionStatusCd)
      this.$set(this.param, 'sysRevision', data.sysRevision)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: data
      })
      this.$set(this.tabItems[1], 'disabled', false)
      this.$set(this.tabItems[2], 'disabled', false)
      this.$set(this.tabItems[3], 'disabled', false)

    },
  }
};
</script>
